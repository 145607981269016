<div id="container"
     fxLayout="column"
     fxLayoutAlign="start center">
    <div id="contextContainer" fxLayout="column"
         fxLayoutAlign="start start"
         fxLayoutGap=5rem>
        <div class="showcase-container">
            <div class="showcase__overlay"
                 fxLayout="column"
                 fxLayoutAlign="end end">
                <img class="showcase__overlay__logo"
                     src="assets/img/logos/rettensteiner-logo.svg"
                     fxShow.lt-md="false">
            </div>
            <span class="showcase__overlay__image"></span>
        </div>
        <div id="inner-pager"
             fxLayout="column"
             fxLayoutAlign="start center"
             fxLayoutGap="3rem">
            <h1 class="title">Herzlich willkommen in der Metzgerei Rettensteiner Scharfetter.</h1>
            <p class="context">Für unsere Kunden ist Fleisch kein billiger Sattmacher, sondern ein wertvoller
                Bestandteil einer ausgewogenen Ernährung.
                <br>
                Diejenigen, die mit Genuss natürliches Fleisch verzehren möchten und auf Herkunft und Qualität denselben
                Wert legen, sind bei uns genau richtig.</p>
            <img src="assets/img/information-box/background/background1.jpg"
                 style="width: 50vw; border-radius: .5rem;">
            <div fxLayout="column"
                 fxLayoutAlign="start center">
                <h1 class="title">Wie wär's mit einem kurzen Einblick in unsere Metzgerei?</h1>
                <iframe id="news-video-top" src="https://www.youtube-nocookie.com/embed/UQdw7DOlMSI" frameborder="0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
            <div fxLayout="column"
                 fxLayoutAlign="start center"
            fxLayoutGap="1rem">
                <h1 class="title">Unsere Auszeichnungen</h1>
                <link href="https://awards.infcdn.net/2024/circle_v2.css" rel="stylesheet"/><div id="circle-r-ribbon" onclick="if(event.target.nodeName.toLowerCase() != 'a') {window.open(this.querySelector('.r-ribbon_title').href);return 0;}" class=""> <div class="r-ribbon_ahead "> <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="160px" height="160px" viewBox="0 0 160 160"> <defs> <path id="heading-arc" d="M 30 80 a 50 50 0 1 1 100 0"></path> </defs> <text class="r-ribbon_ahead-heading " fill="#000" text-anchor="middle"> <textPath startOffset="50%" xlink:href="#heading-arc">Empfohlen</textPath> </text> </svg> </div> <p class="r-ribbon_year">2024</p> <a href="https://restaurantguru.com/Metzgerei-Scharfetter-Saint-Johann-im-Pongau" class="r-ribbon_title f8" target="_blank">Metzgerei Rettensteiner-Scharfetter</a> <div class="r-ribbon_ahead r-ribbon_ahead-bottom"> <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="120px" height="120px" viewBox="0 0 120 120"> <defs> <path id="subheading-arc" d="M 12 60 a 48 48 0 0 0 96 0"></path> </defs> <text class="r-ribbon_ahead-subh" fill="#000" text-anchor="middle"> <textPath startOffset="50%" xlink:href="#subheading-arc"><a href="https://restaurantguru.com" target="_blank">Restaurant Guru</a></textPath> </text> </svg> </div></div>
            </div>
            <!--div id="tour-content" style="width: 100%; padding-top: 2rem;" fxLayout="column"
                 fxLayoutAlign="start center"
                 fxLayoutGap="2rem">
                <div>
                    <h1 class="title">360° Tour</h1>
                    <p class="context">Virtueller Einblick in die Marktstub'n & Metzgerei.</p>
                </div>
                <iframe width='853' height='700'
                        src='https://my.charly.rocks/tour/metzgerei-rettensteiner-scharfetter' frameborder='0'
                        allowfullscreen allow='vr' style="border-radius: .5rem; width: 90%;"></iframe>
            </div>
            sandbox="allow-scripts"-->
            <div id="news-container"
                 fxLayout="column"
                 fxLayoutAlign="start center">
                <app-bdm-news></app-bdm-news>
                <p class="title" style="margin-top: 3rem;">AMA Lukullus Verleihung 2019</p>
                <p class="context">Bereits zum zweiten Mal wurde unser Geschäft mit dem Lukullus
                    ausgezeichnet.
                    Bewertet wurde der Gesamteindruck, die Präsentation der Ware und die Fachkompetenz des
                    Verkaufspersonals.</p>
                <iframe id="news-video" src="https://www.youtube-nocookie.com/embed/v2qaibLBC-4" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
            <!--p *ngIf="internetExplorer" class="overview-text"
               style="margin-top: 2cm; margin-bottom: 1cm; max-width: 70vw; font-size: 30px; text-align: center;">Dieser
                Bereich wird von ihrem Browser nicht unterstützt.</p>
            <div *ngIf="!internetExplorer" id="overviewContainer"
                 fxLayout="row"
                 fxLayoutAlign="space-evenly start"
                 fxShow.lt-md="false">
                <div class="overview"
                     fxLayout="column"
                     fxLayoutAlign="center center" fxLayoutGap="4rem">
                    <div class="overview-inner-container" fxLayout="column" fxLayoutAlign="center center">
                        <img class="overview-image" src="../../../assets/img/information-box/image-view/wild-min.jpg">
                        <p class="title">Wahrer Genuss</p>
                        <p class="context" style="max-width: 12rem; font-size: 22px;">
                            Als Mitgliedsbetrieb der
                            Genussregion Pongauer Wild
                            freuen wir uns Ihnen Wildbret
                            in höchster Qualität anbieten zu können.
                            Unsere Gourmets können sich auf
                            kulinarische Köstlichkeiten freuen.
                        </p>
                    </div>
                </div>
                <div class="overview"
                     fxLayout="column"
                     fxLayoutAlign="center center">
                    <div class="overview-inner-container" fxLayout="column" fxLayoutAlign="center center">
                        <img class="overview-image"
                             src="../../../assets/img/contact/background.jpg">
                        <p class="title">Großes
                            Sortiment</p>
                        <p class="context" style="max-width: 12rem; font-size: 22px;">
                            Sie finden eine große Auswahl von Rindfleisch aus der
                            hauseigenen
                            Schlachterei,
                            Schweinefleisch aus den besten österreichischen Betrieben, hausgemachte Wurst- und
                            Speckspezialitäten und
                            mehr.
                        </p>
                    </div>
                </div>
                <div class="overview"
                     fxLayout="column"
                     fxLayoutAlign="center center">
                    <div class="overview-inner-container" fxLayout="column" fxLayoutAlign="center center">
                        <img class="overview-image"
                             src="../../../assets/img/information-box/image-view/schnaps-min.jpg">
                        <p class="title">
                            Selbstgebranntes</p>
                        <p class="context" style="max-width: 25rem; font-size: 22px;">
                            Eine reiche Auswahl an Bauernschnäpsen und Likören von einem St.
                            Johanner
                            Bauernhof
                            runden unser Sortiment ab.
                        </p>
                    </div>
                </div>

            </div!-->
        </div>
        <!--<div style="width: 600px;">
          <iframe width="{{getScreenWidth()}}" height="450" frameborder="0" style="border:0"
                  src="https://www.google.com/maps/embed/v1/place?q=Rettensteiner Scharfetter&key=AIzaSyDtYRcC9V9esVPX6ySnBnwQawqILSLSWIs&zoom=13&maptype=roadmap"
                  allowfullscreen=""></iframe>
          <br/></div>-->
        <!--<div class="image-view-container" id="image-view-container1" fxFlex="25vh" fxFlex.lt-sm="auto"e
             fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutAlign="center start"
             fxLayoutAlign.lt-md="center center">
          <div id="image-view-3" class="image-view-pic" fxFlex="25%"
               fxLayout="column"
               fxLayoutAlign="center center">
            <p class="image-view-pic-title">Wildspezialitäten</p>
          </div>
          <div id="image-view-2" class="image-view-pic" fxFlex="25%"
               fxLayout="column"
               fxLayoutAlign="center center">
            <p class="image-view-pic-title">Unsere Filiale</p>
          </div>
          <div id="image-view-1" class="image-view-pic" fxFlex="25%"
               fxLayout="column"
               fxLayoutAlign="center center">
            <p class="image-view-pic-title">Weinbrände</p>
          </div>
          <div id="image-view-4" class="image-view-pic" fxFlex="25%"
               fxLayout="column"
               fxLayoutAlign="center center">
            <p class="image-view-pic-title">Sortiment</p>
          </div>
        </div>-->
    </div>
</div>
