import {Component} from '@angular/core';
import {HeaderService} from '../../header.service';
import {take} from 'rxjs/operators';
import {DynamoHttpService} from '@skillgmbh/dynamo';
import {Product} from '../../lib/interfaces/gastroflow/Product';

type Weekday = 'Montag' | 'Dienstag' | 'Mittwoch' | 'Donnerstag' | 'Freitag';

interface Menu {
    vorspeise?: string,
    hauptspeise?: string,
    preis?: string,
}

interface WeekdayMenu {
    day: Weekday,
    menu1?: Menu,
    menu2?: Menu,
}

interface WeeklyMenu {
    menu: Menu,
}

interface WeekdayProductCollection {
    day: Weekday,
    products: Product[];
}

@Component({
    selector: 'app-speiseplan',
    templateUrl: './speiseplan.component.html',
    styleUrls: ['./speiseplan.component.scss']
    })
export class SpeiseplanComponent {
    weekdayMenuCollection: WeekdayMenu[] = [];
    weeklyMenuCollection: WeeklyMenu[] = [];

    constructor(private httpService: DynamoHttpService,
                private headerService: HeaderService) {
        this.headerService.setHeaderTitle('Speiseplan');
        this.headerService.setHeaderImage('assets/img/speiseplan/information-banner-min.jpg');
        this.headerService.setHeaderContext('Gerichte aus dem Mittagsmenü & der Wochenkarte, frisch aus der Metzgerei. Zum Genießen in der Marktstubn & beim Mitnehmen.');

        const weekdays: Weekday[] = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag'];

        this.httpService.get('gastroflow-proxy', 'rettensteiner', 'Menü 1')
            .pipe(take(1))
            .subscribe((products: Product[]): void => {
                const weekdayProductCollection: WeekdayProductCollection[] = [];

                weekdays.forEach((weekday: Weekday): void => {
                    weekdayProductCollection.push(
                        {
                            day: weekday,
                            products: products.filter((product: Product) => product.weekdays.find((productWeekday) => productWeekday.day === weekday))
                        });
                });

                weekdayProductCollection.forEach((weekdayProductCollection: WeekdayProductCollection): void => {
                    const weekdayProducts: Product[] = weekdayProductCollection.products;
                    const product: Product | undefined = weekdayProducts[0];

                    const availableStarters: string[] | undefined = this.getAvailableProductStarters(product);

                    this.weekdayMenuCollection.push({
                        day: weekdayProductCollection.day,
                    });

                    this.weekdayMenuCollection.find((weekdayMenu: WeekdayMenu): boolean => weekdayMenu.day == weekdayProductCollection.day)
                        .menu1 = {
                            vorspeise: availableStarters.join(' oder '),
                            hauptspeise: product ? `${product.name} ${product.description}` : '',
                            preis: this.formatPriceString(product.price)
                        } as Menu;
                });

                // TODO: REFACTOR!!!!

                this.httpService.get('gastroflow-proxy', 'rettensteiner', 'Menü 2')
                    .pipe(take(1))
                    .subscribe((products: Product[]): void => {
                        const weekdayProductCollection: WeekdayProductCollection[] = [];

                        weekdays.forEach((weekday: Weekday): void => {
                            weekdayProductCollection.push(
                                {
                                    day: weekday,
                                    products: products.filter((product: Product) => product.weekdays.find((productWeekday) => productWeekday.day === weekday))
                                });
                        });

                        weekdayProductCollection.forEach((weekdayProductCollection: WeekdayProductCollection): void => {
                            const weekdayProducts: Product[] = weekdayProductCollection.products;
                            const product: Product | undefined = weekdayProducts[0];

                            const availableStarters: string[] | undefined = this.getAvailableProductStarters(product);

                            this.weekdayMenuCollection.find((weekdayMenu: WeekdayMenu): boolean => weekdayMenu.day == weekdayProductCollection.day)
                                .menu2 = {
                                    vorspeise: availableStarters.join(' oder '),
                                    hauptspeise: product ? `${product.name} ${product.description}` : '',
                                    preis: this.formatPriceString(product.price)
                                } as Menu;
                        });
                    });
            });

        this.httpService.get('gastroflow-proxy', 'rettensteiner', 'Wochenkarte')
            .pipe(take(1))
            .subscribe((products: Product[]): void => {
                products.forEach((product: Product): void => {
                    this.weeklyMenuCollection.push({
                        menu: {
                            hauptspeise: product ? `${product.name} ${product.description}` : '',
                            preis: this.formatPriceString(product.price),
                        }
                    });
                });
            });
    }

    private getAvailableProductStarters(product: Product | undefined): string[] {
        return product?.options.filter((option: Product): boolean => option.name.toLowerCase() !== 'keine vorspeise').map((option: Product) => option.name);
    }

    private formatPriceString(price: number | string): string {
        price = parseInt(`${price}`);

        return price.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
}
